@use 'styles/includes' as *;

.RawHtml {
    $root: &;
    @extend %container;
    padding-top: 8rem;
    padding-bottom: 8rem;

    &__Title {
        margin: 0 0 5rem;
        max-width: 101rem;
        font-family: $font-family-bold;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        line-height: 100%;

        @include media(m) {
            font-size: 7.2rem;
        }
    }
}
